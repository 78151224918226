/*

Welcome to my website!

1. This is a prototype, it's not well factored, and yes, it's one giant file!
   ¯\_(ツ)_/¯
2. Code quality is VERY important with established products, and when growing teams.
   This madness right here is a fast party for 1!
3. There's a bunch of accessibility stuff that I haven't implemented yet.
4. How about dark mode? It would be nice wouldn't it.
5. Hover states? Platform specific features add depth, it's on the list!
6. Performance optimizations? Now that I've made it work this is next.

*/

import 'react-native-gesture-handler';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Animated, Easing, TextInput, Text, View, Image, ScrollView, Platform, TouchableOpacity, Linking, KeyboardAvoidingView } from 'react-native';
import { InlineWidget } from "react-calendly";
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TailwindProvider } from "tailwindcss-react-native";
import { NavigationContainer } from '@react-navigation/native';
import { Link } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { WebView } from 'react-native-webview';
import AppRecaptcha from 'react-native-recaptcha-that-works';
import WebRecaptcha from "react-google-recaptcha";
import IntlPhoneField from 'react-native-intl-phone-field';

import Matthew from '../images/matthew.png';
import UQ from '../images/uq.jpg';
import KerbPhoto from '../images/kerb.png';
import PWC from '../images/pwc.jpg';
import Navitas from '../images/navitas.jpg';
import Hack1 from '../images/hackathon1.jpg';
import Beach from '../images/beach.jpg';
import EaccLogo from '../images/eacc.png';
import Catch from '../images/catch.jpg';
import Driving from '../images/driving.jpg';
import Hardware from '../images/hardware.jpg';
import Lab82 from '../images/lab82.png';
import Screenshot from '../images/screenshot.png';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const Loading = ({loading}) => {
  if (!loading) return null;

  const [loadingSpinValue] = useState(new Animated.Value(0));

  const loadingAnimation = useCallback(() => {
    loadingSpinValue.setValue(0);
    Animated.loop(
      Animated.timing(loadingSpinValue, {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true,
      }),
    ).start();

    setTimeout(() => {
      loadingAnimation();
    }, 1000)
  }, [loadingSpinValue]);

  const interpolatedLoadingAnimation = loadingSpinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  useEffect(() => {
    loadingAnimation();
  }, [loadingAnimation]);

  return (
    <View className="p-3 px-5 self-center">
      <Animated.View
        style={{
          transform: [{rotate: interpolatedLoadingAnimation}],
        }}>
          <FontAwesome name="spinner" size={16} color="white" />
      </Animated.View>
    </View>

  );
}

const ChatBox = ({ addMessage = () => {}, disabled = false, scrollToBottom = () => {} }) => {
  const [inputText, setInputText] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValid, setPhoneValid] = useState(false);
  const [sendTapped, setSendTapped] = useState(false);
  const [contactCaptured, setContactCaptured] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const recaptcha = useRef(null);
  const nameField = useRef(null);
  const numberField = useRef(null);

  const finish = async (token) => {
    let newToken = token;
    if (!token || token.length < 1) {
      if (Platform.OS === 'web') {
        newToken = await recaptcha.current.executeAsync();
      } else {
        recaptcha.current.open();
        // This code will execute again
        // when the user has finished the captcha
        // return now.
        return;
      }
    }

    if (userValid()) {
      setContactCaptured(true);
      await send_message(true, newToken);
    }
  }

  const showError = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  }

  const send_message = async (force = false, token = '') => {
    setSendTapped(true);

    if (!contactCaptured && !force) {
      setTimeout(() => {
        nameField?.current?.focus();
      }, 700);
      return;
    }

    if (!loading) { setLoading(true); }

    try {
      const url = 'https://x8ki-letl-twmt.n7.xano.io/api:LAsCfuYp/ms_messages';
      const body = JSON.stringify({
        phone_number: phoneNumber,
        message: inputText,
        name: name,
        token: token,
      });


      const response = await fetch(url, {
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }),
        method: 'POST',
        body: body,
      });

      if (response.ok) {
        const json = await response.json();
        if (json?.id > 0) {
          addMessage(inputText);
          setInputText('');
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      showError();
    }
  }

  const userValid = () => {
    if (!phoneValid || !phoneNumber || !name) return false;
    const nameValid = name.length > 1;
    const phoneValidResult = phoneNumber.length > 1 && phoneValid;
    return phoneValidResult && nameValid;
  }

  const messageValid = inputText.length > 0;
  const sendDisabled = !userValid() || !messageValid;

  const onVerify = token => {
    finish(token);
  }

  const onExpire = () => {
    console.warn('expired captcha!');
    setSendTapped(false);
    showError();
  }

  const web = Platform.OS === 'web';
  return (
    <>
      <View
        className="bg-white/95 flex-row items-center"
        style={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <TextInput
          className="border-[1px] rounded-2xl p-3 m-3 border-gray-300 bg-white flex-1"
          onChangeText={(text) => setInputText(text)}
          value={inputText}
          placeholder={disabled ? "Easy turbo!" : "Type a message..."}
          editable={!disabled}
          selectionColor="#62baf3"
          onFocus={scrollToBottom}
          returnKeyType={'send'}
          onKeyPress={e => {
            if (e.key === 'Enter' && messageValid) {
              send_message();
            }
          }}
          onSubmitEditing={() => {
            if (messageValid) {
              send_message();
            }
          }}
        />
        <TouchableOpacity
          className={`rounded-2xl ${error ? 'bg-red-500' : 'bg-sky-400'} mr-3`}
          onPress={() => send_message()}
          disabled={disabled || !messageValid}
        >
          <Loading loading={loading} />
          {!loading && (<Text className="text-white p-3 font-bold">Send</Text>)}
        </TouchableOpacity>

        {sendTapped && !contactCaptured && (
          <View
            className="bg-sky-400 rounded-2xl shadow-lg m-3 p-3"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: 280,
            }}
          >
            <Text className="text-white font-bold">Let me know your name and phone number so I can message you back</Text>
            <TextInput
              ref={nameField}
              className="rounded-md p-3 my-4 bg-white"
              onChangeText={(text) => setName(text)}
              value={name}
              placeholder="First and last name"
              selectionColor="#62baf3"
              onSubmitEditing={() => {
                numberField?.current?.focus();
              }}
              returnKeyType={'next'}
            />
            <IntlPhoneField
              containerStyle={{
                backgroundColor: '#FFFFFF',
                borderRadius: 6,
                padding: 2,
                borderWidth: 0,
                borderColor: '#FFFFFF',
                paddingLeft: 10,
                paddingRight: 10,
              }}
              onValueUpdate={(result) => { setPhoneNumber(result) }}
              flagUndetermined="🏳"
              onValidation={(isValid) => {
                setPhoneValid(!!isValid);
              }}
              textInputProps={{
                placeholder: 'International phone number',
                ref: numberField,
                returnKeyType: 'send',
                onKeyPress:e => {
                  if (e.key === 'Enter' && !sendDisabled) {
                    finish();
                  }
                },
                onSubmitEditing: () => {
                  if (!sendDisabled) {
                    finish();
                  }
                }
              }}
            />
            <TouchableOpacity
              className={`mt-3 rounded-2xl self-end ${sendDisabled ? "bg-sky-400" : "bg-green-500"}`}
              disabled={disabled || sendDisabled}
              onPress={() => finish()}
            >
              <Loading loading={loading} />
              {!loading && (<Text className="text-white p-3 font-bold">Send &raquo;</Text>)}
            </TouchableOpacity>
            {Platform.OS !== 'web' && (
              <AppRecaptcha
                ref={recaptcha}
                siteKey="6LfDZMUgAAAAAA5jr9VHlA8lxwWxRS5LGN_O_AsS"
                baseUrl="https://www.matthewsalmon.com"
                onVerify={(token) => onVerify(token)}
                onExpire={() => onExpire()}
                size="invisible"
              />
            )}
            {Platform.OS === 'web' && (
              <View style={{ marginTop: 15 }}>
                <WebRecaptcha
                  ref={recaptcha}
                  size="invisible"
                  sitekey="6LfDZMUgAAAAAA5jr9VHlA8lxwWxRS5LGN_O_AsS"
                  badge="inline"
                />
              </View>
            )}
          </View>
        )}
      </View>
    </>
  )
}

const Divider = () => (<View style={{ width: 80, height: 1, backgroundColor: '#EEEEEE' }} className="m-8" />)

const Scroll = ({ children, showsChat = false, scrollRef = null }) => (
  <>
    <ScrollView
      ref={scrollRef}
      className="bg-white flex-1"
      contentContainerStyle={{
        paddingTop: 30,
        paddingBottom: 90,
      }}
    >
      {children}
    </ScrollView>

    {Platform.OS === 'ios' && (
      <View
        className="bg-white/95 flex-row items-center"
        style={{
          height: 44,
          width: '100%',
          position: 'absolute',
          top: 0,
        }}
      ></View>
    )}
  </>
);

const FromMeCard = ({size = 'max-w-3xl', position = 'self-start', children = null, misc = ''}) => (
  <View
    className={`${size} bg-neutral-100 ${position} ${misc} m-8 mb-0 rounded-2xl`}
  >
    <View
      style={{
        height: 30,
        left: -7,
        width: 20,
        bottom: 0,
        backgroundColor: 'rgb(245, 245, 245)',
        borderBottomRightRadius: '16px 14px',
        position: 'absolute'
      }}
    />
    <View className="flex-row">
      {children}
    </View>
    <View
      style={{
        height: 30,
        left: -26,
        width: 26,
        bottom: 0,
        backgroundColor: 'white',
        borderBottomRightRadius: '10px',
        position: 'absolute'
      }}
    />
  </View>
)

const ToMeCard = ({size = 'max-w-3xl', position = 'self-end', children = null, misc = ''}) => (
  <View
    className={`${size} bg-sky-400 ${position} ${misc} m-8 mb-0 rounded-2xl`}
  >
    <View
      style={{
        height: 30,
        width: 20,
        right: -7,
        width: 20,
        bottom: 0,
        backgroundColor: 'rgb(56, 189, 248)',
        borderBottomLeftRadius: '16px 14px',
        position: 'absolute'
      }}
    />
    <View className="flex-row">
      {children}
    </View>
    <View
      style={{
        height: 30,
        right: -26,
        width: 26,
        bottom: 0,
        backgroundColor: 'white',
        borderBottomLeftRadius: '10px',
        position: 'absolute'
      }}
    />
  </View>
)

const Profile = () => {
  return (
    <FromMeCard size="max-w-sm items-center">
      <View className="flex-1 p-3">
        <Text numberOfLines={3} className="pb-2 text-2xl font-black flex-1">Hi, I'm Matthew Salmon</Text>
        <Text className="pb-0 text-md mb-2">an Australian</Text>
        <Text className="pb-0 text-md">Problem Solver</Text>
        <Text className="pb-0 text-md">Maker</Text>
        <Text className="pb-0 text-md">Designer</Text>
        <Text className="pb-0 text-md">Coder</Text>
        <Text className="pb-0 text-md">Leader</Text>
      </View>
      <View className="self-end pb-0 ">
        <Image className="" style={{height: 180, width: 150, borderBottomRightRadius: 16}} source={Matthew} />
      </View>
    </FromMeCard>
  )
}

const Home = () => {
  const scrollViewRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const scrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd();
  }

  const addMessage = (inputText) => {
    if (inputText?.length > 0) {
      setMessages([...messages, inputText]);
      setTimeout(() => scrollToBottom(), 500);
    }
  }

  return (
    <KeyboardAvoidingView
      behavior='position'
      style={{
        flex:1
      }}
      contentContainerStyle={{}}
    >
      {/* Nasty hack to get rid of double scrollbars on the website quickly */}
      {Platform.OS === 'web' && (
        <style> {`#root { overflow: hidden; }`} </style>
      )}
      <Scroll scrollRef={scrollViewRef} showsChat={true}>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Hey there, tell me about yourself!</Text>
        </ToMeCard>
        <Profile />
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Nice to meet you, so what are you looking to do next?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-xl">
          <Text className="text-lg px-4 py-2">I'm looking for an interesting mission to make a really positive contribution towards. I like puzzles, if you've got a challenge on your hands I'm interested!</Text>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Sounds great, what kind of skills do you have?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-lg">
          <Text className="text-lg px-4 py-2">I've spent 5 years on a startup journey as co-founder and Chief Product Officer.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-md">
          <Text className="text-lg px-4 py-2">I'm a world class expert in building mobile apps using React Native.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-lg">
          <Text className="text-lg px-4 py-2">I've solved real world problems using both software and hardware (IoT).</Text>
        </FromMeCard>
        <FromMeCard size="max-w-2xl">
          <Text className="text-lg px-4 py-2">I've designed apps, websites, marketplaces, workflows, location aware systems, multi-home notification systems, web crawlers, and lots more.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-md">
          <Text className="text-lg px-4 py-2">I've integrated everything from marketing systems to payment processors.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-lg">
          <Text className="text-lg px-4 py-2">I've worked with ops teams to deliver high performance geographically redundant services.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-xl">
          <Text className="text-lg px-4 py-2">I've grown and led teams to over 30 people, most recently spanning four countries.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-lg">
          <Text className="text-lg px-4 py-2">I've spent the majority of my career leading both product design and development.</Text>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">What have you worked on?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-2xl">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">I'm currently working for <Text className="font-bold">Boeing Defence Australia.</Text></Text>
            <Text className="text-lg px-4 pb-2">I've been working on a new hobby project, a product for businesses that I'll write more about soon!</Text>
          </View>
        </FromMeCard>
        <FromMeCard size="max-w-2xl">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">I've recently worked as an independent technology consultant:</Text>
            <Text className="text-lg px-4 pb-2 font-bold">TAFE Queensland</Text>
            <Text className="text-lg px-4 pb-2">Working with their digital marketing team on topics like their Marketing Automation Principles and Roadmap, integrations across systems, data transformation, chatbot, and various other technology projects.</Text>
            <Text className="text-lg px-4 pb-2 font-bold">Start-ups</Text>
            <Text className="text-lg px-4 pb-2">Technology services advice on websites, apps, and complex business topics like how to scale white-labelling of apps.</Text>
          </View>
        </FromMeCard>
        {/* <FromMeCard size="max-w-3xl"> */}
          <Image className="rounded-lg my-2 mx-6 mb-0" style={{marginTop: 40, height: 150, width: 130}} source={KerbPhoto} />
        {/* </FromMeCard> */}
        <FromMeCard size="max-w-[80%]">
          <Link to={{ screen: 'Kerb' }}>
            <View className="rounded-2xl" style={{overflow: 'hidden'}}>
              <View className="flex">
                <Text className="text-lg px-4 pt-2 font-black">I co-founded Kerb to take on the world of parking.</Text>
                <Text numberOfLines={0} className="text-lg px-4 py-2">
                  As the technical co-founder of Kerb, I solo built the first versions of Kerb's platform and Kerb's clients for the web, iOS, and Android.
                </Text>
                <Text numberOfLines={0} className="text-lg px-4 py-2">
                  Over my five-year mission as Chief Product Officer, we raised over $6M and built a team of over 30 to focus on bringing a mobile-first experience to parking and aggregate and assist drivers with their daily activities.
                </Text>
                <Text numberOfLines={0} className="text-lg px-4 py-2">
                  I take a hands-on approach to understand our technology stack and being able to apply leverage where needed. I've hired dozens of employees worldwide: engineers, designers, ops, infrastructure and more. I excel at providing clear direction and creating a constructive, dynamic environment.
                </Text>
              </View>
              <View className="m-5 rounded bg-slate-500 flex-row items-center">
                <Text className="text-lg px-4 py-2 flex-1 text-white">This one's a big story, tap to read more</Text>
                <Text className="flex-end px-4 text-white"> &raquo;</Text>
              </View>
            </View>
          </Link>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">A couple of cool highlights from this experience:</Text>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 75, width: 130}} source={Hack1} />
        </FromMeCard>
        <FromMeCard size="lg:max-w-[70%]">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">Winning Toyota's first Hackathon in Australia and a trip to Toyota Japan. We designed an in car parking experience that opened parking gates for you. We had to sync our app with their new in car head units (not CarPlay or Android Auto) and build a working prototype in 24 hours. It was a late night in Melbourne and some fancy bridging between our JavaScript and a lot of new Java code!</Text>
          </View>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 100, width: 130}} source={Catch} />
        </FromMeCard>
        <FromMeCard size="lg:max-w-[70%]">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">On July 1, 2021, KERB was announced as a winner of the Toyota Mobility Foundation's City Architecture for Tomorrow Challenge (CATCH), along with US privacy-first computer vision solution, Numina. Each company won US$250,000 to help bring their mobility solutions to life in Kuala Lumpur.</Text>
          </View>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Wow, that sounds really interesting!</Text>
        </ToMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I've got some cool stories to share around how we discovered and solved problems in novel ways. Let's sit down and talk about it sometime!</Text>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Any other career highlights?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 120, width: 120}} source={PWC} />
        </FromMeCard>
        <FromMeCard size="max-w-4xl">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">I worked as a technical leader on three different start-ups as a part of PwC's Innovation and Venture team. The spaces we worked in were:</Text>
            <Text className="text-lg px-4 pb-2">- Open innovation</Text>
            <Text className="text-lg px-4 pb-2">- Machine learning cashflow analysis and predictions</Text>
            <Text className="text-lg px-4 pb-2">- Multi-platform finance hubs for complex businesses</Text>
          </View>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 150, width: 150}} source={Navitas} />
        </FromMeCard>
        <FromMeCard>
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">At Navitas I led a group-wide technical team that delivered student recruitment focused, apps, websites, and the world's largest (by number of workflows and assets) Marketo marketing automation implementation.</Text>
          </View>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 100, width: 100}} source={Lab82} />
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I also ran an App development studio called Lab82, we made a bunch of experiments and consulted on a few projects. We build a full fledged tool for sales reps to the first iPad when memory leaks were a real problem and the system would kill your app aggressively.</Text>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Did you go to University?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 150, width: 150}} source={UQ} />
        </FromMeCard>
        <FromMeCard size="lg:max-w-[70%]">
          <View className="flex-1">
            <Text className="text-lg px-4 pt-2 font-black">I studied at the University of Queensland, attaining a Bachelor of Information Technology (Interaction Design).</Text>
            <Text className="text-lg px-4 py-1">Today you might refer to this field as User Experience Design. We learned how to design experiences and build and prototype them on computer screens and with physical computing objects.</Text>
            <Text className="text-lg px-4 pb-2">One of my favourite experiences was winning a sponsored mobile computing competition by designing and building a location-based chat experience (think dynamic chat rooms centred on your location at any time). It was challenging to create an iPhone app in Objective-C at the time of the original SDK release on iPhone 3G.</Text>
          </View>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Any other notable reasons we should work with you?</Text>
        </ToMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I am a member of Mensa, there's a lot of nice people who do a lot of interesting things in this community. I read that it's a controversial thing to mention because some people won't like it, but I like to be positive and hope that you'll recognize the value I can help bring to your team.</Text>
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Great, have you got any hobbies?</Text>
        </ToMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I sure do! As a classic introvert...</Text>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 230, width: 150}} source={Driving} />
        </FromMeCard>
        <FromMeCard size="max-w-sm">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">If I'm not taking the kids to soccer you'll probably find me running hot laps on my racing simulator or watching F1 on TV.</Text>
          </View>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 230, width: 150}} source={Hardware} />
        </FromMeCard>
        <FromMeCard size="max-w-xs">
          <View className="flex-1">
            <Text className="text-lg px-4 py-2">Or I might be in the garage coming up with something cool.</Text>
          </View>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I lift weights and stay fit every day and I play Touch Football once a week.</Text>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I also like to read books, relax, and play fun games with my young kids.</Text>
        </FromMeCard>
        <View className="mb-10"></View>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">Tell me about this website</Text>
        </ToMeCard>
        <FromMeCard size="max-w-sm">
          <Text className="text-lg px-4 py-2">Put your money where your mouth is right? </Text>
        </FromMeCard>
        <FromMeCard size="max-w-md">
          <Text className="text-lg px-4 py-2">There's a few concepts I'm toying with:</Text>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">I thought it would be fun to talk to you rather than build a billboard.</Text>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">People are really used to reading a lot of text messages.</Text>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">People like familiar experiences, it makes it easier for us to know what to do.</Text>
        </FromMeCard>
        <FromMeCard>
          <Text className="text-lg px-4 py-2">App and website convergence is taking a lot longer than I thought it would!</Text>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Text className="text-lg px-4 py-2">This website is built using Expo and is deployable as a React website, or as a React Native app for iOS, Android, MacOS, Windows, etc. If you're starting something new being able to take it quickly to a lot of screens with a small team is really powerful and fun.</Text>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="" style={{height:220, width: 110}} source={Screenshot} />
        </FromMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">I've got something amazing going on that I think you'd be great help with...</Text>
        </ToMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">I'm currently working on big things, which limits my availability. Happy to offer advice where I can :)</Text>
        </ToMeCard>
        <ToMeCard>
          <Text className="text-lg px-4 py-2 text-white">How can I get in touch with you?</Text>
        </ToMeCard>
        <FromMeCard size="max-w-sm">
          <Text className="text-lg px-4 py-2">You can type in the message box and hit send, or jump over to the calendar tab 😄</Text>
        </FromMeCard>
        <FromMeCard size="max-w-sm">
          <Text className="text-lg px-4 py-2">Thanks for chatting, here's a picture of a nice beach that I'd like to vist ⛱️</Text>
        </FromMeCard>
        <FromMeCard size="max-w-3xl">
          <Image className="rounded-lg" style={{height: 150, width: 300}} source={Beach} />
        </FromMeCard>
        {messages?.length > 0 && (
          messages.map((message, index) => (
            <View key={index}>
              <ToMeCard>
                <Text className="text-lg px-4 py-2 text-white">{message}</Text>
              </ToMeCard>
              {index === 0 && (
                <FromMeCard>
                  <Text className="text-lg px-4 py-2">Thanks, I'll text you back soon</Text>
                </FromMeCard>
              )}
            </View>
          ))
        )}
      </Scroll>
      <ChatBox disabled={messages.length > 3} addMessage={(message) => addMessage(message)} scrollToBottom={scrollToBottom}/>
    </KeyboardAvoidingView>
  );
}

const Calendly = () => {
  const url = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ0lkWKo37EYkRLGSK6SL-HgaYIYzB18eHDfyC3tveiiuWry5VAYAIXsFFlElBVUOvA9qGeiXd_o?gv=true';

  if (Platform.OS === 'web') {
    return (
      <View style={{flex: 1, backgroundColor: '#FFFFFF'}}>
        <InlineWidget styles={{flex: 1}} url={url} />
      </View>
    )
  } else {
    return (
      <WebView
        source={{ uri: url }}
      />
    );
  }
}

const Thinks = () => {
  const _openLink = async (href) => {
    if (await Linking.canOpenURL(href)) {
      await Linking.openURL(href);
    }
  };

  return (
    <ScrollView
      className="flex bg-white"
      contentContainerStyle={{ alignItems: 'center',  }} //justifyContent: 'center'
    >
      <View className="px-3">
        <Text className="text-md mt-8 text-gray-400 text-center">
          These are some topics I'm finding interesting now.
        </Text>
        <Text className="text-md mt-2 mb-8 text-gray-400 text-center">
          This page will change from time to time, topics and silly ideas will come and go!
        </Text>
      </View>

      <Divider />

      <View className="m-8 max-w-2xl">
        <View>
          <TouchableOpacity onPress={() => _openLink('https://en.wikipedia.org/wiki/Effective_accelerationism/')}>
            <View className=" p-6 mx-auto rounded-xl bg-neutral-100 sm:flex-row items-center space-x-4">
              <View className="shrink-0 pr-3">
                <Image className="" style={{height: 113, width: 162}} source={EaccLogo} />
              </View>
              <View className="flex-initial">
                <View className="pt-4 sm:pt-0">
                  <Text numberOfLines={0} className=" text-lg text-slate-600">E/Acc - The essence of e/acc is a belief, based in thermodynamics, that life inherently seeks continuous expansion and that our civilizational destiny lies among the stars &raquo;</Text>
                </View>
              </View>
            </View>
          </TouchableOpacity>
          <View>
            <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">I've spent way too much time thinking about this, and I think the e/acc attitude is far more right than wrong. Humans make better things. That's what we do. We build. Humanity stops when other people can stop you from building, stop you from growing. There's always nuance, bad things will happen that we couldn't predict, but to stop people from building because you're afraid is worse. Let's go make better things.</Text>
          </View>
        </View>
      </View>

      <Divider />

      <View className="m-8 max-w-2xl">
        <View className="flex p-3">
            <Text numberOfLines={0} className="text-xl text-center text-slate-600">"The real question is not whether machines think but whether men do." - B.F. Skinner</Text>
          </View>
        <TouchableOpacity onPress={() => _openLink('https://www.youtube.com/watch?v=HbGoUwmqIEQ')}>
          <View className="mt-8 p-6 mx-auto rounded-xl bg-neutral-100 flex items-center space-x-4">
            <View className="">
              <Text numberOfLines={0} className="text-lg text-slate-600">Richard Dawkins and Jordan Peterson Discuss Psychedelics, Consciousness, and Artificial Intelligence &raquo;</Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _openLink('https://www.ted.com/talks/sam_harris_can_we_build_ai_without_losing_control_over_it/transcript')}>
          <View className="mt-8 p-6 mx-auto rounded-xl bg-neutral-100 flex items-center space-x-4">
            <View className="">
              <Text numberOfLines={0} className="text-lg text-slate-600">Sam Harris: Can we build AI without losing control over it? &raquo;</Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => _openLink('https://openai.com/blog/chatgpt')}>
          <View className="mt-8 p-6 mx-auto rounded-xl bg-neutral-100 flex items-center space-x-4">
            <View className="">
              <Text numberOfLines={0} className="text-lg text-slate-600">ChatGPT: Optimizing Language Models for Dialogue &raquo;</Text>
            </View>
          </View>
        </TouchableOpacity>
        <View>
          <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">I enjoy exploring the link between our understanding of humans and how we really work, and how we really struggle to think about AI and what it means.</Text>
        </View>
        <View>
          <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">This one turned into a really hot topic with the release of ChatGPT! If you haven't tried it you really should. I think the key takeaways at the moment are that these tools are amazing, but not necessarily accurate. OpenAI is trying very hard to stop it from just making stuff up, but that brings its own set of problems. My hope is that more of this kind of work gets open sourced so that we can better understand the trade-offs that are being built in.</Text>
        </View>
        <View>
          <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">Overall: I'm pro AI and haven't been convinced by any of the skeptical or "be afraid" threads yet. I spent all day yesterday working with ChatGPT on some programming topics that I was unfamiliar with. It was by far the fastest technical learning I've ever done.</Text>
        </View>
      </View>

      <Divider />

      <View className="m-8 max-w-2xl">
        <TouchableOpacity onPress={() => _openLink('https://1729.com/the-network-state')}>
          <View className="p-6 mx-auto bg-neutral-100 rounded-xl flex flex-row items-center space-x-4">
            <View className="flex-1">
              <Text numberOfLines={0} className="text-lg text-slate-600">The Network State by Balaji S. Srinivasan &raquo;</Text>
            </View>
          </View>
        </TouchableOpacity>
        <View>
          <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">I'm a follower of the 1729 project because I think it's an amazingly deep exercise in imaging a possible future. You might not agree with what you read, but I hope you find it expanding how you think.</Text>
        </View>
      </View>

      <Divider />

      <View className="m-8 max-w-2xl">
        <TouchableOpacity onPress={() => _openLink('https://www.mensa.org.au/giftedchildren/gifted-children')}>
          <View className=" p-6 mx-auto bg-neutral-100 rounded-xl flex flex-row items-center space-x-4">
            <View className="flex-1">
              <Text numberOfLines={0} className="text-lg text-slate-600">Mensa resources on gifted children &raquo;</Text>
            </View>
          </View>
        </TouchableOpacity>
        <View>
          <Text numberOfLines={0} className="mt-8 p-8 text-lg text-sky-400 text-center">There are millions(!) of gifted children out there and I hope we can foster the skills they have towards helping the communities around them. I don't want to overplay this because you know, they're gifted, they'll be fine. Do they see a great future to build towards?</Text>
        </View>
      </View>
      <Divider />
    </ScrollView>
  )
}

const Links = () => {
  const _openLink = async (href) => {
    if (await Linking.canOpenURL(href)) {
      await Linking.openURL(href);
    }
  };

  return (
    <ScrollView className="flex-1 bg-white" contentContainerStyle={{ alignItems: 'center', justifyContent: 'center'}}>
      <View className="px-3">
        <Text className="mt-8 text-md text-gray-400 text-center">
          More of maker, less of publicist.
        </Text>
        <Text className="text-md mt-2 mb-8 text-gray-400 text-center">
          I love ideas! Lets talk about them, I've shared a few over in the Thinks tab.
        </Text>
      </View>
      <Divider />
      <TouchableOpacity onPress={() => _openLink('https://discordapp.com/users/512049487288729636')}>
        <View className="mx-6 mt-6 p-6 max-w-sm mx-auto bg-neutral-100 rounded-xl flex flex-row items-center space-x-4">
          <View className="shrink-0">
            <FontAwesome5 name="discord" size={24} color="#7289da" />
          </View>
          <View className="flex">
            <Text className="text-xl font-medium text-black">Discord</Text>
            <Text numberOfLines={2} className="text-slate-500 flex">invig#6872</Text>
            <Text numberOfLines={2} className="text-slate-500 flex">id: 512049487288729636</Text>
          </View>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => _openLink('https://github.com/invig')}>
        <View className="mt-6 p-6 max-w-sm mx-auto bg-neutral-100 rounded-xl flex flex-row items-center space-x-4">
          <View className="shrink-0">
            <FontAwesome5 name="github" size={24} color="#6e5494" />
          </View>
          <View className="flex-1">
            <Text className="text-xl font-medium text-black">Github</Text>
            <Text numberOfLines={2} className="text-slate-500">invig</Text>
          </View>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => _openLink('https://twitter.com/invig')}>
        <View className="mt-6 p-6 max-w-sm mx-auto bg-neutral-100 rounded-xl flex flex-row items-center space-x-4">
          <View className="shrink-0">
          <FontAwesome5 name="twitter" size={24} color="#62baf3" />
          </View>
          <View className="flex-1">
            <Text className="text-xl font-medium text-black">Twitter</Text>
            <Text numberOfLines={2} className="text-slate-500">@invig</Text>
          </View>
        </View>
      </TouchableOpacity>
    </ScrollView>
  )
}

const Kerb = () => {
  return (
    <Scroll>
      <FromMeCard size="max-w-[80%]">
        <View className="mt-1 ml-0 sm:mt-0">
          <Image className="rounded-lg m-3" style={{height: 150, width: 130}} source={KerbPhoto} />
        </View>
      </FromMeCard>
      <ToMeCard>
        <Text className="text-lg px-4 py-2 text-white">Kerb, lets dive in!</Text>
      </ToMeCard>
      <FromMeCard>
        <Text numberOfLines={0} className="text-lg px-4 py-2">Here's a long list of highlights from things we built, there's a lot more detail that we could talk tech on some time!</Text>
      </FromMeCard>
      <FromMeCard>
        <Text className="text-lg px-4 py-2">Kerb Cloud - Fully automated marketplace</Text>
      </FromMeCard>
      <FromMeCard>
        <View className="flex-1">
          <Text className="text-lg px-4 py-2">- Location-aware to know or infer where users are and personalise their experience.</Text>
          <Text className="text-lg px-4 py-2">- Support for modelling car parks in full depth (start with one rule for many spots and go down to unique settings and prices for each parking spot).</Text>
          <Text className="text-lg px-4 py-2">- Hourly, daily, weekly, and monthly pricing models (subscription and on-demand payments)</Text>
          <Text className="text-lg px-4 py-2">- A range of discount options.</Text>
          <Text className="text-lg px-4 py-2">- Timeboxing of spots (e.g. make this spot available Monday to Friday at these rates and then a different rate on weekends, or even different rates per day)</Text>
          <Text className="text-lg px-4 py-2">- Controlled tandem parking spaces with driver-to-driver communication ("Hey, I'm leaving at 4 pm, can you let me out?")</Text>
          <Text className="text-lg px-4 py-2">- Customers support and deferred customer support options (e.g. let people out of the car park to prevent a traffic jam and then lock their account until their support or payment actions are complete).</Text>
          <Text className="text-lg px-4 py-2">- A fully automated marketplace (including KYC review), payments and pay-outs in dozens of countries, and multiple providers (e.g. Stripe, Wise, PaymentRails, Omise, GrabPay, TrueMoney, PromptPay, and more...)</Text>
          <Text className="text-lg px-4 py-2">- Fully localised text (20+ languages)</Text>
          <Text className="text-lg px-4 py-2">- Gate control with API endpoints for providers to integrate with Kerb, Kerb Box, or existing solutions like calling the gate to open it. Includes rules engine for defining control points.</Text>
          <Text className="text-lg px-4 py-2">- Fraud detection (credit card fraud, device abuse, parking abuses)</Text>
          <Text className="text-lg px-4 py-2">- Automated migration and rollback, staged deployments to test for failures.</Text>
          <Text className="text-lg px-4 py-2">- AWS, multi-region by design, Terraform, ECR, Kubernetes, Postgres, Redis, S3, Route53, SNS, SES.</Text>
          <Text className="text-lg px-4 py-2">-Crawlers of external websites capture parking data, allow drivers access to non-Kerb spaces and build intelligence.</Text>

        </View>
      </FromMeCard>
      <FromMeCard>
        <Text className="text-lg px-4 py-2">Kerb Apps and Website</Text>
      </FromMeCard>
      <FromMeCard>
        <View className="flex-1">
          <Text className="text-lg px-4 py-2">- Drivers can rapidly use maps, searches, and filters to find spaces appropriate for them.</Text>
          <Text className="text-lg px-4 py-2">- Driver's payment details are stored to enable quick charging in advance, on entry or exit.</Text>
          <Text className="text-lg px-4 py-2">- Pre-booking is a standard feature; Kerb reserves the spot, unlike other providers that sell the spot but don't reserve it.</Text>
          <Text className="text-lg px-4 py-2">- Drivers open gates right from their phone with a tap or allow the app to wake up in the background and do it for you on gate approach!</Text>
          <Text className="text-lg px-4 py-2">- Complete self-service tooling for space owners on all platforms so that they can create and manage their spaces.</Text>
          <Text className="text-lg px-4 py-2">- Drivers can book parking for their friends.</Text>
          <Text className="text-lg px-4 py-2">- Drivers can share their space with family members, and Kerb will enforce that only one person uses it at a time.</Text>
          <Text className="text-lg px-4 py-2">- Drivers get price-comparison signals and assistance, saving money on parking.</Text>
          <Text className="text-lg px-4 py-2">- New drivers have access to a web-based express parking feature to get them parking as they drive up to a gate without needing to install an app and create a traffic problem. Kerb remembers drivers across sessions, even in a browser.</Text>
          <Text className="text-lg px-4 py-2">- Dashboards to help space owners understand how their space is being used and optimise it.</Text>
          <Text className="text-lg px-4 py-2">- Built-in support tools to communicate with drivers (chat, cancel bookings, move dates, etc.)</Text>
          <Text className="text-lg px-4 py-2">- Space security layers include private, public, code-required, and hidden spots.</Text>
          <Text className="text-lg px-4 py-2">- KYC and pay-out management.</Text>
          <Text className="text-lg px-4 py-2">- And lots more</Text>
        </View>
      </FromMeCard>
      <FromMeCard>
        <Text className="text-lg px-4 py-2">Kerb Box - an IoT device that can plug into any boom gate with a couple of wires to allow us to open the gate for a driver</Text>
      </FromMeCard>
      <FromMeCard>
        <View className="flex-1">
          <Text className="text-lg px-4 py-2">- Why? Zero dollars spent on insanely expensive ($10k+ per year per gate) software integrations with existing suppliers.</Text>
          <Text className="text-lg px-4 py-2">- Secure VPN and WebSockets. Lightning-fast communication between Kerb cloud and driver's devices achieves sub-1-second gate open times.
</Text>
          <Text className="text-lg px-4 py-2">- The cloud-first software philosophy allows drivers dynamic gate-specific access and includes anti-passback features.
</Text>
          <Text className="text-lg px-4 py-2">- Always on redundant cell network connections radically simplifies installs. When you're letting hundreds of cars in during peak morning hours, it has to work.
</Text>
          <Text className="text-lg px-4 py-2">- Bluetooth beacons allow the Kerb app on the driver's phone to detect a Kerb Box and start to interact with it as their vehicle approaches.
</Text>
          <Text className="text-lg px-4 py-2">- Gathers environmental and usage data, battery-powered to detect gate failures and enable driver re-routing.
</Text>
          <Text className="text-lg px-4 py-2">- The box is configurable using self-service tools so that it can be hot-swapped or installed redundantly.
Deployed in 5 countries (Australia, Ireland, Malaysia, Thailand, and Canada).</Text>
          <Text className="text-lg px-4 py-2">- Manufactured in Indonesia</Text>
        </View>
      </FromMeCard>
      <ToMeCard>
        <Text className="text-lg px-4 py-2 text-white">Wow, that's a lot of stuff!</Text>
      </ToMeCard>
      <FromMeCard>
        <Text className="text-lg px-4 py-2">I'm sure there's more I'm forgetting. I think we built some of the best technology in the world in this space. Do let me know if you want to nerd out on any of these topics!</Text>
      </FromMeCard>
      <ToMeCard>
        <Text className="text-lg px-4 py-2 text-white">Thanks, will do.</Text>
      </ToMeCard>
      <FromMeCard size="max-w-sm py-2">
        <Link to={{ screen: 'Home' }} className="py-2">
          <Text className="text-lg px-4">Tap here to jump back to the main story &raquo;</Text>
        </Link>
      </FromMeCard>
    </Scroll>
  )
}

const TabStack = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#62baf3',
        tabBarInactiveTintColor: '#8e8e8f',
      }}
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={() => ({
            title: 'Matthew Salmon',
            // tabBarLabel: 'Matthew',
            header: () => {},
            tabBarIcon: ({ color, focused, size }) => {
              return <Ionicons name="person" size={24} color={focused ? '#62baf3' : '#8e8e8f'} />
            },
            tabBarLabelStyle: {
              fontWeight: '600',
            }
          })
        }
      />
      <Tab.Screen
        name="Calendar"
        component={Calendly}
        options={{
          title: 'Schedule a meeting',
          tabBarLabel: 'Calendar',
          tabBarIcon: ({ focused }) => <Ionicons name="ios-calendar-sharp" size={24} color={focused ? '#62baf3' : '#8e8e8f'} />,
          tabBarLabelStyle: {
            fontWeight: '600',
            }
        }}
      />
      <Tab.Screen
        name="Thinks"
        component={Thinks}
        options={{
          title: 'Thinks',
          title: 'Interesting topics',
          tabBarLabel: 'Thinks',
          tabBarIcon: ({ focused }) => <MaterialCommunityIcons name="head-lightbulb-outline" size={24} color={focused ? '#62baf3' : '#8e8e8f'} />,
          tabBarLabelStyle: {
            fontWeight: '600',
          }
        }}
      />
      <Tab.Screen
        name="Links"
        component={Links}
        options={{
          title: 'Links',
          tabBarIcon: ({ focused }) => <FontAwesome name="connectdevelop" size={24} color={focused ? '#62baf3' : '#8e8e8f'} />,
          tabBarLabelStyle: {
            fontWeight: '600',
          }
        }}
      />
    </Tab.Navigator>
  );
}

export default function App() {
  const linking = {
    enabled: true,
    prefixes: ['https://www.matthewsalmon.com', 'invig://'],
    config: {
      screens: {
        HomeStack: {
          initialRouteName: 'Home',
          screens: {
            Home: 'chat',
            Calendar: 'calendar',
            Thinks: 'thinks',
            Links: 'links',
          }
        },
        Kerb: 'chat/kerb',
      },
    },
  };

  return (
    <TailwindProvider>
      <NavigationContainer linking={linking}>
        <Stack.Navigator>
          <Stack.Screen
            name="HomeStack"
            component={TabStack}
            options={{
              header: () => {},
              title: 'Matthew Salmon',
            }}
          />
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen name="Kerb" component={Kerb} />
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
    </TailwindProvider>
  );
}
